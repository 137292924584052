<template>
    <div class="container my-5">
        <h1>{{ $t('app.errorPage.heading') }}</h1>
        <p>{{ $t('app.errorPage.subheading') }}</p>
        <div class="my-4 bg-white rounded shadow p-4">
            <h3>{{ $t('app.errorPage.contactUs') }}</h3>
            <p class="text-bigger mt-4 mb-2">
                <span data-icon="mdi:phone" class="iconify mr-2 text-cyrrus-orange"></span> <a href="tel:+420226258226">+420 <strong>226 258 226</strong></a>
            </p>
            <p class="text-bigger">
                <span data-icon="mdi:at" class="iconify mr-2 text-cyrrus-orange"></span> <a href="mailto:fxinfo@cyrrus.cz">fxinfo@cyrrus.cz</a>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ErrorPage'
}
</script>
